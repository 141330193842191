import React, { useContext, useEffect } from "react";
import "./Homepage.css";

import SlickList2 from "../SlickList2";
import Navbar from "../Navbar";
import Footer from "../footer";
import assetContext from "../../context/AssetsContext/AssetContext";
// import Banner from "../../assets/images/test_banner.png";
import Banner from "../../assets/images/background_banner.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import { FeaturedPropertiesList } from "./BentoGrid/Bentogrid";
import { WhatWeOffer } from "./BentoGrid/WhatWeOffer";

import BackGroundImage from "../../assets/images/luxury.jpeg";
import BackGroundImage2 from "../../assets/images/luxury2.jpeg";
import ViewAllGradient from "../../assets/images/ViewAll.png";
import ViewAllImage from "../../assets/images/ViewAllImage.png";
import Verified from "../../assets/images/verified.png";

import { IMAGE_PATH } from "../../constant";

import ProjectTypesCard from "../ProjectTypesCard";
import SliderHome from "./SliderHome";
import HomeSlider from "./HomeStorySlider/HomeSlider";
import HomeStoryVIew from "./HomeStorySlider/HomeStoryVIew";

const Homepage = () => {
  // const navigate = useNavigate();

  // const handleDataReceived = async (data) => {
  //   const filtersx = data.filters;
  //   const queryParams = new URLSearchParams({
  //     page: 1,
  //     property_type: filtersx !== null ? filtersx.property_type : [],
  //     property_status: filtersx !== null ? filtersx.property_status : [],
  //     city: filtersx !== null ? filtersx.city : [],
  //     property_builder: filtersx !== null ? filtersx.property_builder : [],
  //     bedroom: filtersx !== null ? filtersx.bedroom : [],
  //     property_price: filtersx !== null ? filtersx.property_price : 20,

  //     bath_rooms: filtersx !== null ? filtersx.bath_rooms : [],
  //     balconies: filtersx !== null ? filtersx.balconies : [],
  //     door_facing: filtersx !== null ? filtersx.door_facing : [],
  //     furnishing_status: filtersx !== null ? filtersx.furnishing_status : [],
  //     parking: filtersx !== null ? filtersx.parking : [],

  //     property_name: data.text,
  //   }).toString();

  //   navigate(`/properties?${queryParams}`);
  // };

  // const context = useContext(assetContext);
  // const { getAvailableFilters, availableFilters } = context;

  // useEffect(() => {
  //   // getAssets({ page: currentPage });
  //   getAvailableFilters();

  //   // eslint-disable-next-line
  // }, []);

  //   const navigate = useNavigate();

  // const handleDataReceived = async (data) => {
  //   const filtersx = data.filters;
  //   const queryParams = new URLSearchParams({
  //     page: 1,
  //     property_type: filtersx !== null ? filtersx.property_type : [],
  //     property_status: filtersx !== null ? filtersx.property_status : [],
  //     city: filtersx !== null ? filtersx.city : [],
  //     property_builder: filtersx !== null ? filtersx.property_builder : [],
  //     bedroom: filtersx !== null ? filtersx.bedroom : [],
  //     property_price: filtersx !== null ? filtersx.property_price : 20,

  //     bath_rooms: filtersx !== null ? filtersx.bath_rooms : [],
  //     balconies: filtersx !== null ? filtersx.balconies : [],
  //     door_facing: filtersx !== null ? filtersx.door_facing : [],
  //     furnishing_status: filtersx !== null ? filtersx.furnishing_status : [],
  //     parking: filtersx !== null ? filtersx.parking : [],

  //     property_name: data.text,
  //   }).toString();

  //   navigate(`/properties?${queryParams}`);
  // };

  // const context = useContext(assetContext);
  // const {
  //   getAvailableFilters,
  //   availableFilters,
  //   assetsfeatured,
  //   totalAssetsFeatured,
  //   getAssetsFeatured,
  // } = context;
  // useEffect(() => {
  //   // getAssets({ page: currentPage });
  //   getAvailableFilters();

  //   getAssetsFeatured({
  //     page: 1,
  //   });

  //   // eslint-disable-next-line
  // }, []);

  // const HandleClick = (slug) => {
  //   window.open(`/property/${slug}`, "_blank");
  //   localStorage.setItem("projectData", JSON.stringify(assetsfeatured));
  // };
  // const HandleFeaturedClick = (slug) => {
  //   navigate(`/featured`);
  // };

  const navigate = useNavigate();

  const handleDataReceived = async (data) => {
    const filtersx = data.filters;
    const queryParams = new URLSearchParams({
      page: 1,
      property_type: filtersx !== null ? filtersx.property_type : [],
      property_status: filtersx !== null ? filtersx.property_status : [],
      city: filtersx !== null ? filtersx.city : [],
      property_builder: filtersx !== null ? filtersx.property_builder : [],
      bedroom: filtersx !== null ? filtersx.bedroom : [],
      property_price: filtersx !== null ? filtersx.property_price : 20,

      bath_rooms: filtersx !== null ? filtersx.bath_rooms : [],
      balconies: filtersx !== null ? filtersx.balconies : [],
      door_facing: filtersx !== null ? filtersx.door_facing : [],
      furnishing_status: filtersx !== null ? filtersx.furnishing_status : [],
      parking: filtersx !== null ? filtersx.parking : [],

      property_name: data.text,
    }).toString();

    navigate(`/properties?${queryParams}`);
  };

  const context = useContext(assetContext);
  const {
    getAvailableFilters,
    availableFilters,
    assetsfeatured,
    totalAssetsFeatured,
    getAssetsFeatured,
  } = context;
  useEffect(() => {
    // getAssets({ page: currentPage });
    getAvailableFilters();

    getAssetsFeatured({
      page: 1,
    });

    // eslint-disable-next-line
  }, []);

  const HandleClick = (slug) => {
    window.open(`/property/${slug}`, "_blank");
    localStorage.setItem("projectData", JSON.stringify(assetsfeatured));
  };
  const HandleFeaturedClick = (slug) => {
    navigate(`/featured`);
  };

  return (
    <div>
      <Navbar />

      <ToastContainer position="top-center" />

      {/* <div
        className="flex bg-[#e1e6ef] flex-row justify-center items-center"
        style={{
          height: "70vh",
       // marginTop:200
        }}
      >
        
        <HomeStoryVIew />

      </div> */}
      {/* <HomeSlider /> */}
      <SliderHome />

      <ProjectTypesCard />

      {/* <FeaturedPropertiesList/> */}

      <div
        style={{
          backgroundColor: "#ebf4ec",
          padding: "80px 10px",
        }}
        className="flex justify-center items-center flex-col"
      >
        <h2
          style={{
            fontSize: 40,
            marginBottom: 30,
          }}
          className="text-center text-lg font-bold leading-none text-gray-900"
        >
          What makes us unique in the market
        </h2>
        <h2 className="text-center text-md w-full md:w-[50vw] leading-normal align-center mb-10 font-semibold leading-none text-gray-900">
          Exclusive properties from 2 crore, personalized support, no spam
          calls, professional video tours, and visits for qualified buyers only.
          Experience a tailored property search.
        </h2>
        <WhatWeOffer />
      </div>

      <div
        style={{
          position: "relative",
          // width: "100%",
          height: "450px",
          overflow: "hidden",
          margin: "5vw",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${BackGroundImage})`, // Use template literals
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // background: "rgba(147, 121, 184, 0.1)",
            backgroundColor: "#293F66",
            opacity: "80%",
            borderRadius: "10px",
            padding: "20px",
            // width: "80%",
            // maxWidth: "40vw",
            textAlign: "center",
            zIndex: 2,
            //backdropFilter: "blur(5px)",
            ///boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            color: "#fff",
          }}
          className="flex justify-center items-center flex-col  w-80vw   md:w-60vw lg:w-50vw border border-white border-1"
        >
          <h2
            style={{
              marginTop: "10px",
              // fontSize: "30px",
              fontWeight: 600,
              //color:"#9379B8",
              fontFamily: "Poppins, sans-serif",
            }}
            className="text-xl md:text-3xl"
          >
            Luxury
          </h2>

          <div
            style={{
              width: "5vw",
              height: 3,

              backgroundColor: "red",
            }}
          ></div>

          <p
            style={{
              margin: "10px 0px",
              // fontSize: "18px",
              fontWeight: 500,
              fontFamily: "Poppins, sans-serif",
            }}
            className="text-sm md:text-xl"
          >
            Boutique & Luxury Properties
          </p>
          <a
            href="/properties"
            style={{
              fontSize: 14,
              fontFamily: "Poppins, sans-serif",
            }}
            className="text-sm md:text-xl flex items-center mt-6 mb-4 justify-center gap-x-1 py-2 px-4 text-black font-medium text-white rounded-md border-white border-2 md:inline-flex"
          >
            DISCOVER LUXURY
          </a>
        </div>
      </div>

      <div
        style={
          {
            // marginLeft: "5vw",
            // marginRight: "5vw",
          }
        }
        className="bg-white flex flex-col justify-center items-center"
      >
        <h2
          style={{
            fontSize: 40,
            marginBottom: 30,
          }}
          className="text-center text-lg font-bold leading-none text-gray-900"
        >
          Featured Projects
        </h2>
        <h2 className="text-center text-md w-full md:w-[50vw] leading-normal align-center mb-10 font-semibold leading-none text-gray-900">
          "Curious about project appreciation values? Explore our newly added
          resale inventory and discover its valuation."{" "}
        </h2>

        {assetsfeatured.length > 0 ? (
          <div
            style={{
              borderRadius: 10,
              //backgroundColor:"black"
            }}
            className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-8  py-10   md:grid-cols-2 lg:grid-cols-3"
          >
            {assetsfeatured.slice(0, 9).map((asset, index) =>
              index === 8 ? (
                <div
                  style={{
                    backgroundImage: `url(${ViewAllGradient})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",

                    borderRadius: 10,
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    width: "99%",
                    height: "100%",
                    display: "flex",
                    fontFamily: "Poppins, sans-serif",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <label
                    className="leading-none"
                    style={{
                      color: "black",
                      backgroundColor: "transparent",
                      fontSize: 20,
                      marginTop: 20,
                    }}
                  >
                    Featured Properties
                  </label>

                  <img
                    style={{
                      //color: "black",
                      marginTop: 20,
                      marginLeft: 20,
                      marginRight: 20,
                      backgroundColor: "transparent",
                    }}
                    src={ViewAllImage}
                    alt="Error"
                  />

                  <label
                    style={{
                      color: "black",
                      marginLeft: "15px",
                      marginRight: "15px",
                      marginTop: "25px",
                      marginBottom: "25px",
                      fontSize: 14,
                      backgroundColor: "transparent",
                    }}
                  >
                    Dive into luxury living with our exclusive projects,
                    offering unparalleled quality and innovation.
                  </label>

                  <label
                    onClick={HandleFeaturedClick}
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    View All {totalAssetsFeatured} properties
                  </label>
                </div>
              ) : (
                <div
                  key={index}
                  style={{
                    position: "relative",

                    //  backgroundColor: "white",
                  }}
                  className="bg-[#ebf4ec] rounded-lg p-4 flex flex-col items-center"
                >
                  <div className="w-full">
                    <img
                      onClick={() => HandleClick(asset.slug)}
                      src={
                        IMAGE_PATH +
                        asset.cover_image.fieldname +
                        "/" +
                        asset.cover_image.filename
                      }
                      alt="ExxxErr"
                      className="object-cover rounded-lg h-64 w-full"
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      top: "-2vh",
                      //  backgroundColor: "white",
                    }}
                  >
                    <img
                      onClick={() => HandleClick(asset.slug)}
                      src={Verified}
                      alt="Verified"
                      className="h-[4vh]"
                    />
                  </div>
                  <div className="card-bottom w-full">
                    <h1
                      onClick={() => HandleClick(asset.slug)}
                      className="text-black font-bold text-lg"
                    >
                      {asset.property_name}
                    </h1>
                    <div className="flex justify-between items-center mt-2 mb-4">
                      <div className="flex text-sm">{asset.bedroom}</div>
                      {/* <div className="flex text-sm">2000 sq.ft</div> */}
                      <h1 className="text-sm">
                        {asset.property_type}, {asset.property_dimension} sq.ft
                      </h1>
                    </div>
                    <h3 className="text-black text-md mt-4">
                      ₹{asset.property_price} Cr
                    </h3>
                    <h2 className="text-gray-500 text-sm mt-2">
                      {asset.location}, {asset.city}
                    </h2>
                  </div>
                </div>
              )
            )}
          </div>
        ) : null}
      </div>

      <div
        style={{
          position: "relative",
          //  width: "100%",
          height: "450px",
          overflow: "hidden",
          margin: "5vw",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${BackGroundImage2})`, // Use template literals
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // background: "rgba(147, 121, 184, 0.1)",
            backgroundColor: "#293F66",
            opacity: "80%",
            borderRadius: "10px",
            padding: "20px",
            // width: "80%",
            // maxWidth: "40vw",
            textAlign: "center",
            zIndex: 2,
            // backdropFilter: "blur(5px)",
            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            color: "#fff",
          }}
          className="flex justify-center items-center flex-col  w-80vw   md:w-60vw lg:w-50vw border border-white border-1"
        >
          <h2
            style={{
              marginTop: "10px",
              // fontSize: "30px",
              fontWeight: 600,
              //color:"#9379B8",
              fontFamily: "Poppins, sans-serif",
            }}
            className="text-xl md:text-3xl"
          >
            Commercial
          </h2>

          <div
            style={{
              width: "5vw",
              height: 3,

              backgroundColor: "red",
            }}
          ></div>

          <p
            style={{
              margin: "10px 0px",
              // fontSize: "18px",
              fontWeight: 500,
              fontFamily: "Poppins, sans-serif",
            }}
            className="text-sm md:text-xl"
          >
            Commercial with confidence
          </p>
          <a
            href="/properties"
            style={{
              fontSize: 14,
              fontFamily: "Poppins, sans-serif",
            }}
            className="text-sm md:text-xl flex items-center mt-6 mb-4 justify-center gap-x-1 py-2 px-4 text-black font-medium text-white rounded-md border-white border-2 md:inline-flex"
          >
            EXPLORE COMMERCIAL REAL ESTATE
          </a>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#efecff",
        }}
        className="px-2 sm:px-14"
      >
        <div
          style={{
            padding: 30,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: 600,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Top Commuinities
          </div>
          <a
            href="/featured"
            className="hidden md:block flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-gray-800 hover:bg-gray-700 active:bg-gray-900 border-2 border-black  rounded-md md:inline-flex"
          >
            Show all communities
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>

        <SlickList2 />
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
