import React from "react";
import { BentoGrid, BentoGridItem } from "./Bento-Grid";
import DedicatedRelManager from "../../../assets/images/dedicated_relationship_manager.jpg";
import NoSpamCalls from "../../../assets/images/no_spam_calls.png";
import VideoShoot from "../../../assets/images/video_shoot.jpg";
import ExclusiveProperty from "../../../assets/images/luxury_property_2cr+.jpg";
import GenuineSiteVisits from "../../../assets/images/genuine_site_visit.png";

import {
  IconClipboardCopy,
  IconFileBroken,
  IconSignature,
  IconTableColumn,
} from "@tabler/icons-react";

export function WhatWeOffer() {
  return (
    <BentoGrid className="max-w-7xl mx-auto md:auto-rows-[20rem]">
      {items.map((item, i) => (
        <BentoGridItem
          key={i}
          title={item.title}
          description={item.description}
          header={item.header}
          className={item.className}
          icon={item.icon}
        />
      ))}
    </BentoGrid>
  );
}

const items = [
  {
    title: "Exclusive Property only(2cr+)",
    description:
      "Premium properties over 2 crore for discerning buyers seeking exclusivity and luxury.",
    header: (
      <div
        style={{
          backgroundColor: "#d0dcf2",
        }}
        className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl   dark:bg-dot-white/[0.2] bg-dot-black/[0.2] [mask-image:radial-gradient(ellipse_at_center,white,transparent)]  border border-transparent dark:border-white/[0.2] bg-neutral-100 dark:bg-black"
      >
        <img
          src={ExclusiveProperty}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
    ),
    className: "md:col-span-2",
    icon: <IconClipboardCopy className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Dedicated Relationship Manager",
    description: "Your dedicated manager for personalized property assistance.",
    header: (
      <div
        style={{
          backgroundColor: "#d0dcf2",
        }}
        className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl   dark:bg-dot-white/[0.2] bg-dot-black/[0.2] [mask-image:radial-gradient(ellipse_at_center,white,transparent)]  border border-transparent dark:border-white/[0.2] bg-neutral-100 dark:bg-black"
      >
        <img
          src={DedicatedRelManager}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        />{" "}
      </div>
    ),
    className: "md:col-span-1",
    icon: <IconFileBroken className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "No Spam Calls(Privacy to personal data)",
    description:
      "Your privacy matters—no spam calls, and your personal data is kept secure.",
    header: (
      <div
        style={{
          backgroundColor: "#d0dcf2",
        }}
        className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl   dark:bg-dot-white/[0.2] bg-dot-black/[0.2] [mask-image:radial-gradient(ellipse_at_center,white,transparent)]  border border-transparent dark:border-white/[0.2] bg-neutral-100 dark:bg-black"
      >
        <img
          src={NoSpamCalls}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        /> 
      </div>
    ),
    className: "md:col-span-1",
    icon: <IconSignature className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Profesional  Video shoot of the Property",
    description:
      "High-quality, professional video shoots to capture and showcase every detail of your property.",
    header: (
      <div
        style={{
          backgroundColor: "#d0dcf2",
        }}
        className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl   dark:bg-dot-white/[0.2] bg-dot-black/[0.2] [mask-image:radial-gradient(ellipse_at_center,white,transparent)]  border border-transparent dark:border-white/[0.2] bg-neutral-100 dark:bg-black"
      >
        <img
          src={VideoShoot}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        /> 
      </div>
    ),
    className: "md:col-span-1",
    icon: <IconTableColumn className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Qualified and Genuine Visits only.",
    description:
      "Only qualified buyers will be invited for property visits, ensuring a focused and meaningful experience.",
    header: (
      <div
        style={{
          backgroundColor: "#d0dcf2",
        }}
        className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl   dark:bg-dot-white/[0.2] bg-dot-black/[0.2] [mask-image:radial-gradient(ellipse_at_center,white,transparent)]  border border-transparent dark:border-white/[0.2] bg-neutral-100 dark:bg-black"
      >
          <img
          src={GenuineSiteVisits}
          style={{
            objectFit: "cover",
            width: "100%",
          }}
        />
       </div>
    ),
    className: "md:col-span-1",
    icon: <IconTableColumn className="h-4 w-4 text-neutral-500" />,
  },
];
