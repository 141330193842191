import Footer from "../footer";
import BoxRevealDemo from "./Boxreveal";

import React, { useState, useContext } from "react";

import { auth } from "../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import assetContext from "../../context/AssetsContext/AssetContext";

import Navbar from "../Navbar";
import { PRIMARY_COLOR } from "../../constant";

const ReferralPage = () => {
  const [selectedOption, setSelectedOption] = useState("buyer");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",

    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleData = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setErrors({ ...errors, [field]: "" }); // Clear error when the user starts typing
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.toString().trim()) newErrors.name = "Name is required.";
    if (!formData.email.toString().trim())
      newErrors.email = "Email is required.";
    if (!formData.phone.toString().trim())
      newErrors.phone = "Phone is required.";
    // if (!formData.otp.toString().trim()) newErrors.otp = "OTP is required.";
    if (!formData.message.toString().trim())
      newErrors.message = "Message is required.";
    return newErrors;
  };

  const onSignup = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      onCaptchVerify();

      const appVerifier = window.recaptchaVerifier;

      const formatPh = "+91" + formData.phone.toString();
      console.log(formatPh);

      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setShowOTP(true);
          toast.success("OTP has been sent the given phone!");
        })
        .catch((error) => {
          console.log(error);
        });
      // Proceed with form submission or next steps
    }
  };

  const context = useContext(assetContext);
  const { registerLead } = context;

  const handleClick = async (data) => {
    try {
      await registerLead({
        name: data.name,
        email: data.email,
        phone: data.phone,
        message: selectedOption + data.message,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [otp, setOtp] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onOTPVerify(otp) {
    console.log(otp);

    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        handleClick(formData);
        toast.success("Your callback request has been sent successfully");
        setFormData({
          name: "",
          email: "",
          phone: "",
        });
        setOtp("");
      })
      .catch((err) => {
        console.log(err);
        toast.error("OTP invalid!");
      });
  }
  return (
    <div>
      <Navbar />
      <div className="flex flex-col lg:flex-row mx-auto p-4 mt-20 mb-20 max-w-7xl justify-between items-start">
        <BoxRevealDemo className="flex-1" />
        {/* <ShadCNForm className="flex-1"/> */}
        <div
          style={{
            backgroundColor: "#3d537a",
            padding: 15,

            borderRadius: 6,

            border: "solid 1px #7c90b2",
            marginTop: 20,
          }}
          className="w-full lg:w-auto"
        >
          <div
            id="recaptcha-container"
            //  className="bg-white h-[650px] sticky py-12 lg:flex lg:justify-center"
          ></div>
          <div className="flex flex-col md:flex-row justify-start items-start md:justify-center md:items-center ">
            <div
              style={{
                width: "100%",
                // border: "1px solid #ccc",
                padding: 10,

                flex: 1,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginBottom: "10px",
                    flex: 1,
                    fontSize: 14,
                    color: "white",
                  }}
                >
                  NAME
                </label>
              </div>
              <input
                type="text"
                required
                onChange={(e) => handleData("name", e.target.value)}
                placeholder="Enter your name"
                className="w-full pr-3 py-2 text-white-900 bg-transparent border-none outline-none focus:outline-none focus:border-none shadow-sm"
                style={{
                  border: "none", // Remove any default border
                  outline: "none", // Remove any default outline
                  boxShadow: "none",
                  color: "white",
                  borderRadius: 6,
                  backgroundColor: "#293f66",
                  border: "solid 1px #7c90b2",
                }}
              />
              {errors.name && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {errors.name}
                </div>
              )}
            </div>
            <div
              style={{
                width: "100%",
                // border: "1px solid #ccc",
                padding: 10,
                flex: 1,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginBottom: "10px",
                    flex: 1,
                    fontSize: 14,
                    color: "white",
                  }}
                >
                  EMAIL
                </label>
              </div>
              <input
                type="email"
                required
                onChange={(e) => handleData("email", e.target.value)}
                placeholder="Enter your email"
                className="w-full pr-3 py-2 text-white-900 bg-transparent border-none outline-none focus:outline-none focus:border-none shadow-sm"
                style={{
                  border: "none", // Remove any default border
                  outline: "none", // Remove any default outline
                  boxShadow: "none",
                  // backgroundColor: "#F7F7F7",
                  color: "white",
                  borderRadius: 6,
                  backgroundColor: "#293f66",
                  border: "solid 1px #7c90b2",
                }}
              />
              {errors.email && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {errors.email}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-start items-start md:justify-center md:items-center ">
            <div
              style={{
                width: "100%",
                // border: "1px solid #ccc",
                padding: 10,
                flex: 1,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    marginBottom: "10px",
                    flex: 1,
                    fontSize: 14,
                    color: "white",
                  }}
                >
                  PHONE
                </label>
              </div>
              <input
                type="tel"
                required
                onChange={(e) => handleData("phone", e.target.value)}
                placeholder="Enter your phone(10 digits)"
                className="w-full pr-3 py-2 text-white-900 bg-transparent border-none outline-none focus:outline-none focus:border-none shadow-sm"
                style={{
                  border: "none", // Remove any default border
                  outline: "none", // Remove any default outline
                  boxShadow: "none",
                  //backgroundColor: "#F7F7F7",
                  color: "white",
                  borderRadius: 6,
                  backgroundColor: "#293f66",
                  border: "solid 1px #7c90b2",
                }}
              />
              {errors.phone && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {errors.phone}
                </div>
              )}
            </div>
            {showOTP ? (
              <div
                style={{
                  width: "100%",
                  // border: "1px solid #ccc",
                  padding: 10,
                  flex: 1,
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    style={{
                      marginBottom: "10px",
                      flex: 1,
                      fontSize: 14,
                      color: "white",
                    }}
                  >
                    OTP
                  </label>
                </div>
                <input
                  type="number"
                  required
                  value={otp}
                  //onChange={(e) => handleData("message", e)}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="6 digts OTP"
                  className="w-full pr-3 py-2 text-white-900 bg-transparent border-none outline-none focus:outline-none focus:border-none shadow-sm"
                  style={{
                    border: "none", // Remove any default border
                    outline: "none", // Remove any default outline
                    boxShadow: "none",
                    // backgroundColor: "#F7F7F7",
                    color: "white",
                    borderRadius: 6,
                    backgroundColor: "#293f66",
                    border: "solid 1px #7c90b2",
                  }}
                />
                {errors.otp && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {errors.otp}
                  </div>
                )}
              </div>
            ) : null}
          </div>

          <div
            style={{
              width: "100%",
              // border: "1px solid #ccc",
              padding: 10,
              flex: 1,
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <label
                style={{
                  marginBottom: "10px",
                  flex: 1,
                  fontSize: 14,
                  color: "white",
                }}
              >
                SEND US A NOTE
              </label>
            </div>
            <textarea
              required
              placeholder="Write your message"
              rows={6}
              onChange={(e) => handleData("message", e.target.value)}
              style={{
                border: "none",

                outline: "none",

                boxShadow: "none",
                // backgroundColor: "#F7F7F7",
                color: "white",
                borderRadius: 6,
                maxLines: 6,

                backgroundColor: "#293f66",
                border: "solid 1px #7c90b2",
              }}
              className="w-full pr-3 py-2 text-white-900 bg-transparent border-none outline-none focus:outline-none focus:border-none shadow-sm"
            />
            {errors.message && (
              <div style={{ color: "red", marginTop: "5px" }}>
                {errors.message}
              </div>
            )}

            {/* <input
              type="text"
              required
              placeholder="Write your message"
              className="w-full pr-3 py-2 text-white-900 bg-transparent border-none outline-none focus:outline-none focus:border-none shadow-sm"
            /> */}
          </div>
          {showOTP ? (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <div
                style={{
                  backgroundColor: PRIMARY_COLOR,
                  marginTop: 20,
                  width: "50%",
                  alignItems: "end",
                  marginBottom: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // border: "solid 1px #7c90b2"
                }}
                onClick={() => onOTPVerify(otp)}
                className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium  border-2 border-white  rounded-md md:inline-flex"
              >
                Verify and submit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  s
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <div
                style={{
                  backgroundColor: PRIMARY_COLOR,
                  marginTop: 20,
                  width: "50%",
                  alignItems: "end",
                  marginBottom: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // border: "solid 1px #7c90b2"
                }}
                onClick={() => onSignup()}
                className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium  border-2 border-white  rounded-md md:inline-flex"
              >
                Verify phone
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  s
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ReferralPage;
